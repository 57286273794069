import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from '@lingui/macro';
import { Form, Grid, Input } from 'semantic-ui-react';
import styled from 'styled-components';

import { actionTypes, adminLogAs } from 'actions/user';
import { createLoadingSelector } from 'reducers/ui';

import { LoginMessageContainer } from 'components/login/LoginLayout';
import Header from 'components/ui/Header';
import { MediumPaddedSegment } from 'components/ui/Segment';
import { ButtonAccent } from 'components/ui/button';
import { PageLayout } from 'components/ui/layout/Page';

import * as svars from 'assets/style/variables';

const CustomTextInput = styled(Input)`
  &&& input {
    padding: ${svars.spaceNormalLarge};
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: ${svars.spaceMedium};
  margin-left: ${svars.spaceNormal};
`;

const loadingSelector = createLoadingSelector([
  actionTypes.ADMIN_LOG_AS_REQUEST,
]);

function AdminSupportPage() {
  const [username, setUsername] = useState('');
  const [logAsError, setLogAsError] = useState(false);
  const dispatch = useDispatch();
  const loading = useSelector(loadingSelector);
  const onLogAs = useCallback(() => {
    if (!loading && username && username.trim()) {
      dispatch(adminLogAs(username.trim()))
        .then(() => {
          setLogAsError(false);
        })
        .catch(() => setLogAsError(true));
    }
  }, [loading, username]);

  return (
    <PageLayout>
      <MediumPaddedSegment>
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Header>Log in as ...</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row as={Form}>
            <Grid.Column width={6}>
              <CustomTextInput
                fluid
                autoFocus
                id="Username"
                icon="user"
                iconPosition="left"
                onChange={(_, { value }) => setUsername(value)}
                placeholder={t({ id: 'username' })}
                value={username}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <ButtonAccent onClick={onLogAs} loading={loading}>
                Log as
              </ButtonAccent>
            </Grid.Column>
            <Grid.Row>
              <ErrorMessageContainer>
                {logAsError ? (
                  <LoginMessageContainer error={logAsError}>
                    Connection failed. No account is associated with this email.
                    Please enter a valid email address.
                  </LoginMessageContainer>
                ) : null}
              </ErrorMessageContainer>
            </Grid.Row>
          </Grid.Row>
        </Grid>
      </MediumPaddedSegment>
    </PageLayout>
  );
}

AdminSupportPage.propTypes = {};

AdminSupportPage.defaultProps = {};

export default AdminSupportPage;
