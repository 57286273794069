import { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { Grid, Icon } from 'semantic-ui-react';

import {
  setExtraConcept,
  unfoldPredictionApproval,
  updatePredictionApproval,
} from 'actions/validation';
import { getExtraConceptsDropdown } from 'reducers/validation';

import { ButtonSecondary } from 'components/ui/button/index';
import { StyledDropdown } from 'components/ui/inputs/Dropdown';
import PredictionTable from 'components/ui/prediction/table';

import commonPropTypes from 'utils/commonPropTypes';
import { accentInsensitiveSearch } from 'utils/helpers';

import * as svars from 'assets/style/variables';

class ValidateReviewChunk extends Component {
  getOnChangeHandler(ontology, chunkIndex) {
    const { onSetExtraConcept } = this.props;
    return (e, { value }) => {
      onSetExtraConcept(ontology, chunkIndex, value);
    };
  }

  getHandlePredictionApprovalChange(ontology, chunkIndex) {
    const { onUpdatePredictionApproval } = this.props;
    return (value, prediction) => {
      onUpdatePredictionApproval(
        ontology,
        chunkIndex,
        prediction.db_concept.id,
        value.checked
      );
    };
  }

  render() {
    const {
      ontologies,
      reviewChunkIndex,
      ontologyToPredictions,
      ontologyToValidation,
      disabled,
      displayLiveResults,
    } = this.props;
    const { onUnfoldPredictionApproval } = this.props;
    return (
      <Grid.Row columns="2">
        {Object.entries(ontologyToPredictions).map(
          ([ontologyName, predictionsAllChunks], it0) => {
            const { predictionApproval, extraConceptIds } =
              ontologyToValidation[ontologyName][reviewChunkIndex];
            return (
              <Grid columns={2} key={`${ontologyName}`}>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <PredictionTable
                      predictions={
                        predictionsAllChunks.items_texts[reviewChunkIndex]
                      }
                      predictionsApproval={predictionApproval}
                      approvalChangeHandler={this.getHandlePredictionApprovalChange(
                        ontologyName,
                        reviewChunkIndex
                      )}
                      unfoldPredictionApproval={() =>
                        onUnfoldPredictionApproval(
                          ontologyName,
                          reviewChunkIndex
                        )
                      }
                      tablePosition={it0}
                      title={
                        <span
                          style={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {ontologyName}
                          <ButtonSecondary
                            style={{
                              float: 'right',
                              fontSize: svars.fontSizeBase,
                              marginTop: svars.spaceSmall,
                              minWidth: 0,
                            }}
                            icon={<Icon name="eye" size="large" />}
                            onClick={displayLiveResults}
                          />
                        </span>
                      }
                      disabled={disabled}
                    />
                  </Grid.Column>

                  <Grid.Column width={6}>
                    Add missing concept
                    <StyledDropdown
                      placeholder="Missing concept"
                      fluid
                      multiple
                      disabled={disabled}
                      search={accentInsensitiveSearch}
                      selection
                      options={getExtraConceptsDropdown(
                        ontologies.find(({ name }) => name === ontologyName),
                        ontologyToValidation,
                        ontologyName,
                        reviewChunkIndex
                      )}
                      onChange={this.getOnChangeHandler(
                        ontologyName,
                        reviewChunkIndex
                      )}
                      value={extraConceptIds}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            );
          }
        )}
      </Grid.Row>
    );
  }
}

ValidateReviewChunk.propTypes = {
  onUpdatePredictionApproval: PropTypes.func.isRequired,
  onUnfoldPredictionApproval: PropTypes.func.isRequired,
  onSetExtraConcept: PropTypes.func.isRequired,
  ontologies: commonPropTypes.validation_ontologies.isRequired,
  reviewChunkIndex: PropTypes.number.isRequired,
  ontologyToPredictions: PropTypes.objectOf(
    PropTypes.shape({
      items_texts: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.shape({
            db_concept: PropTypes.shape({ id: PropTypes.string }),
          })
        )
      ),
    })
  ).isRequired,
  ontologyToValidation: PropTypes.shape({
    system: PropTypes.arrayOf(
      PropTypes.shape({
        predictionApproval: PropTypes.objectOf(PropTypes.bool),
        extraConceptIds: PropTypes.arrayOf(PropTypes.number),
      })
    ),
  }).isRequired,
  disabled: PropTypes.bool,
  displayLiveResults: PropTypes.func.isRequired,
};

ValidateReviewChunk.defaultProps = {
  disabled: false,
};

export default connect(
  (state) => ({
    ontologies: state.validation.ontologies,
    ontologyToValidation: state.validation.ontologyToValidation,
  }),
  {
    onSetExtraConcept: setExtraConcept,
    onUpdatePredictionApproval: updatePredictionApproval,
    onUnfoldPredictionApproval: unfoldPredictionApproval,
  }
)(ValidateReviewChunk);
