import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';

import moment from 'moment';

import {
  competitiveCumulatedVolumeHelpMessage,
  differentialSentimentHelpMessage,
} from 'components/customer/v2/helpMessageV2';
import { LargeSpacePaddedRow } from 'components/ui/Grid';
import Header from 'components/ui/Header';

import commonPropTypes from 'utils/commonPropTypes';
import {
  floatFormatter,
  numberFormatter,
  onePrecisionFloatFormatter,
} from 'utils/formatter';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import DashboardKpi from '../dashboard/DashboardKpi';

function BenchmarkHeader({
  viewFacet,
  differentialSentiment,
  differentialSentimentIncrease,
  comparativeGroupsNChunks,
  comparativeGroupsNChunksIncrease,
  isLoading,
}) {
  const updateDate = moment(viewFacet.aggregates_update_date);
  return (
    <LargeSpacePaddedRow style={{ minHeight: svars.dashboardHeaderMinHeight }}>
      <Grid.Column width={7}>
        <p>
          <Trans id="analysis-updated-on" /> {' : '}
          {updateDate.format(svars.dateTimeFormat)}
        </p>
        <Header
          style={{
            fontSize: svars.fontSizeBig,
            maxWidth: svars.textMaxWidth,
          }}
        >
          <Header.Content style={{ paddingBottom: svars.spaceMedium }}>
            <Trans id="benchmark" render={capitalizedTranslation} />
          </Header.Content>
          <Header.Subheader>
            <Trans id="dashboard-benchmark.description" />.
          </Header.Subheader>
        </Header>
      </Grid.Column>
      <Grid.Column verticalAlign="bottom" textAlign="left" width={9}>
        <div style={{ display: 'inline-flex' }}>
          <DashboardKpi
            name={
              <Trans id="competitors-volume" render={capitalizedTranslation} />
            }
            loading={isLoading}
            value={comparativeGroupsNChunks}
            increase={comparativeGroupsNChunksIncrease}
            formatter={numberFormatter}
            increaseFormatter={onePrecisionFloatFormatter}
            description={<Trans id={competitiveCumulatedVolumeHelpMessage} />}
            wrapperStyle={{ marginRight: svars.spaceMediumLarge }}
            valueColor={svars.volumeColor}
          />
          <DashboardKpi
            name={
              <Trans
                id="differential-sentiment"
                render={capitalizedTranslation}
              />
            }
            loading={isLoading}
            value={differentialSentiment}
            increase={differentialSentimentIncrease}
            formatter={floatFormatter}
            increaseFormatter={floatFormatter}
            description={<Trans id={differentialSentimentHelpMessage} />}
            absoluteIncrease
          />
        </div>
      </Grid.Column>
    </LargeSpacePaddedRow>
  );
}

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

BenchmarkHeader.propTypes = {
  viewFacet: commonPropTypes.viewFacet.isRequired,
  differentialSentiment: stringOrNumber.isRequired,
  differentialSentimentIncrease: stringOrNumber.isRequired,
  comparativeGroupsNChunks: stringOrNumber.isRequired,
  comparativeGroupsNChunksIncrease: stringOrNumber.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default BenchmarkHeader;
