import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  Link as RouterLink,
  isRouteErrorResponse,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import { Trans, t } from '@lingui/macro';

import { receiveLogout } from 'actions/user';

import * as Sentry from '@sentry/react';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import Link from 'components/ui/Link';
import { ButtonSecondaryAccent } from 'components/ui/button';
import emptyDashboardUrl from 'components/ui/svg/undraw_bug_fixing_oc-7-a.svg';

import config from 'config';

import * as svars from 'assets/style/variables';

import LogoHomeLink from './LogoHomeLink';

function SupportPageActions() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutAndRedirectToLogin = useCallback(() => {
    dispatch(receiveLogout()).then(() => {
      navigate('/login');
    });
  }, []);
  return (
    <div>
      <p>
        <Trans id="no-home.could-not-find-what-looking-for" />
      </p>
      <Trans>
        no-home.reach-support{' '}
        <Link base primary href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>
      </Trans>
      <br />
      <RouterLink to="/login">
        <ButtonSecondaryAccent
          onClick={logoutAndRedirectToLogin}
          style={{
            margin: `${svars.spaceMediumLarge} 0`,
            marginBottom: svars.spaceLarge,
          }}
        >
          <Trans id="back-to-login" />
        </ButtonSecondaryAccent>
      </RouterLink>
    </div>
  );
}

function CustomerSupportPage() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    Sentry.captureException(
      `An error occured from the router: ${error.data?.message}; ${error.status}; ${error.statusText}`
    );
  } else {
    Sentry.captureException('Not an error from the router. Check permissions');
  }
  return (
    <>
      <LogoHomeLink />

      <EmptyDataPage
        maxHeight="300px"
        layout="row"
        i18nHeaderText={t({ id: "could-not-find-you-a-home" })}
        illustrationUrl={emptyDashboardUrl}
        actionComponent={<SupportPageActions />}
      />
    </>
  );
}

export default CustomerSupportPage;
