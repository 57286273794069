import { Link as RouterLink } from 'react-router-dom';

import styled from 'styled-components';

import logoImageSrc from 'components/ui/svg/logo.svg';

import * as svars from 'assets/style/variables';

const ImgLogo = styled.img`
  &&&&&&& {
    position: absolute;
    width: ${svars.logoWidth};
    margin-top: ${svars.spaceMediumLarge};
    margin-left: ${svars.spaceMediumLarge};
  }
`;

const LogoItemStyled = styled.div`
  &&& {
    transition: transform 0.3s;
    border: none;
    margin: auto;

    &:hover {
      transform: scale(1.05);
    }
    :before {
      width: 0px;
    }
  }
`;

function LogoHomeLink() {
  return (
    <LogoItemStyled as={RouterLink} to="/" key="logo" name={logoImageSrc}>
      <ImgLogo src={logoImageSrc} alt="No logo" />
    </LogoItemStyled>
  );
}

export default LogoHomeLink;
