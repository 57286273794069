import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Trans } from '@lingui/macro';
import { Container, Divider, Form, FormField } from 'semantic-ui-react';
import styled from 'styled-components';

import { requestUserProfile, saveUserProfile } from 'actions/user';
import { pseudonymSelector, usernameSelector } from 'selectors/user';

import { LargeHeader } from 'components/ui/Header';
import StyledSegment from 'components/ui/Segment';
import {
  AnalyticsAwareButton,
  ButtonTransparentAccent,
} from 'components/ui/button/index';
import Label from 'components/ui/inputs/Label';
import { ProfileImage } from 'components/ui/inputs/ProfileImage';
import { TextInput } from 'components/ui/inputs/TextInput';
import { ButtonLineLayout } from 'components/ui/layout/Page';

import { inputMaxWidth, spaceMediumLarge } from 'assets/style/variables';

const StyledContainer = styled(Container)`
  padding: ${spaceMediumLarge};
`;

const StyledFormField = styled(FormField)`
  max-width: ${inputMaxWidth};
`;

function SettingsPage() {
  const dispatch = useDispatch();

  const originalPseudonym = useSelector(pseudonymSelector);
  const originalUserEmail = useSelector(usernameSelector);

  const [pseudonym, setPseudonym] = useState(originalPseudonym);
  const [userEmail, setUserEmail] = useState(originalUserEmail);
  const [isDirty, setIsDirty] = useState(false);

  const handleSaveUserProfile = useCallback(async () => {
    await dispatch(saveUserProfile(pseudonym));
    await dispatch(requestUserProfile());
    setIsDirty(false);
  }, [dispatch, pseudonym]);

  useEffect(() => {
    dispatch(requestUserProfile());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setPseudonym(value);
    setIsDirty(value !== originalPseudonym || userEmail !== originalUserEmail);
  };

  const handleRevertChanges = () => {
    setPseudonym(originalPseudonym);
    setUserEmail(originalUserEmail);
    setIsDirty(false);
  };

  return (
    <StyledContainer>
      <StyledSegment>
        <LargeHeader>
          <Trans id="user-profile" />
        </LargeHeader>
        <Divider />
        <Form>
          <StyledFormField>
            <Label>
              <Trans id="user-pseudonym" />
            </Label>
            <TextInput
              name="pseudonym"
              value={pseudonym}
              onChange={handleInputChange}
              label={{
                content: <ProfileImage name={originalPseudonym} />,
              }}
              labelPosition="right"
            />
          </StyledFormField>
          <ButtonLineLayout>
            {isDirty ? (
              <ButtonTransparentAccent onClick={handleRevertChanges}>
                <Trans id="user-revert-changes" />
              </ButtonTransparentAccent>
            ) : null}
            <AnalyticsAwareButton
              onClick={handleSaveUserProfile}
              disabled={!isDirty}
            >
              <Trans id="user-profile-save" />
            </AnalyticsAwareButton>
          </ButtonLineLayout>
        </Form>
      </StyledSegment>
    </StyledContainer>
  );
}

export default SettingsPage;
