
import { Link as RouterLink, useRouteError } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Icon } from 'semantic-ui-react';

import * as Sentry from '@sentry/react';

import LogoHomeLink from 'components/customer/LogoHomeLink';
import EmptyDataPage from 'components/ui/EmptyDataPage';
import Link from 'components/ui/Link';
import { ButtonSecondaryAccent } from 'components/ui/button';
import emptyDashboardUrl from 'components/ui/svg/undraw_bug_fixing_oc-7-a.svg';

import config from 'config';

import * as svars from 'assets/style/variables';

const browsersInfo = [
  {
    name: 'Google Chrome',
    icon: 'chrome',
    detectionKeyword: 'chrome',
    cookiesHelpLink: 'https://support.google.com/accounts/answer/32050',
    updateBrowserHelpLink: 'https://support.google.com/chrome/answer/95414',
    getBrowserHelpLink: 'https://www.google.com/chrome/',
  },
  {
    name: 'Firefox',
    icon: 'firefox',
    detectionKeyword: 'firefox',
    cookiesHelpLink:
      'https://support.mozilla.org/kb/clear-cookies-and-site-data-firefox',
    updateBrowserHelpLink:
      'https://support.mozilla.org/kb/update-firefox-latest-release',
  },
  {
    name: 'Safari',
    icon: 'safari',
    detectionKeyword: 'safari',
    cookiesHelpLink:
      'https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac',
    updateBrowserHelpLink: 'https://support.apple.com/HT204416',
  },
  {
    name: 'Edge',
    icon: 'edge',
    detectionKeyword: 'edg',
    cookiesHelpLink:
      'https://support.microsoft.com/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09',
    updateBrowserHelpLink:
      'https://support.microsoft.com/topic/update-to-the-new-microsoft-edge-182d0668-e3f0-49da-b8bb-db5675245dc2',
  },
  {
    name: 'Internet Explorer',
    icon: 'internet explorer',
    detectionKeyword: 'msie',
    cookiesHelpLink:
      'https://support.microsoft.com/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d',
    updateBrowserHelpLink:
      'https://support.microsoft.com/windows/run-the-latest-version-of-internet-explorer-11-ea628df4-50ce-8019-f9f4-468e39685cea',
  },
];

const detectBrowser = () => {
  const agent = window.navigator?.userAgent?.toLowerCase();
  for (const {
    detectionKeyword,
    ...item
    // name,
    // cookiesHelpLink,
    // updateBrowserHelpLink,
  } of browsersInfo) {
    if (agent.includes(detectionKeyword)) {
      return item;
    }
  }
  return {};
};
const preferredBrowsersNames = ['Chrome', 'Firefox'];

function ErrorActions() {
  const {
    cookiesHelpLink,
    updateBrowserHelpLink,
    getBrowserHelpLink,
    name,
    icon,
  } = detectBrowser();
  const browserName = (
    <>
      {name}
      <Icon name={icon} style={{ padding: `0 ${svars.spaceSmall}` }} />
    </>
  );
  return (
    <div>
      <p>
        <Trans id="general-error-boundary.support-is-aware" />
      </p>
      <Trans id="general-error-boundary.we-suggest-actions" />
      <ul>
        {!preferredBrowsersNames.includes(name) ? (
          <li>
            <Trans id="general-error-boundary.use-chrome-firefox-browser" />
            &nbsp;
            {getBrowserHelpLink ? (
              <>
                (
                <Link base target="_blank" href={updateBrowserHelpLink}>
                  <Trans>get {browserName}</Trans>
                </Link>
                )
              </>
            ) : null}
          </li>
        ) : null}
        <li>
          <Trans id="general-error-boundary.upgrade-browser" />
          &nbsp;
          {updateBrowserHelpLink ? (
            <>
              (
              <Link
                base
                target="_blank"
                href={updateBrowserHelpLink}
                icon="search"
              >
                <Trans>get help for {browserName}</Trans>
              </Link>
              )
            </>
          ) : null}
        </li>
        <li>
          <Trans id="general-error-boundary.empty-cookies" />
          &nbsp;
          {updateBrowserHelpLink ? (
            <>
              (
              <Link base target="_blank" href={cookiesHelpLink}>
                <Trans>get help for {browserName}</Trans>
              </Link>
              )
            </>
          ) : null}
        </li>
      </ul>
      <Trans>
        general-error-boundary.reach-support-for-emergency{' '}
        <Link base primary href={`mailto:${config.supportEmail}`}>
          {config.supportEmail}
        </Link>
      </Trans>
      <br />
      <RouterLink to="/">
        <ButtonSecondaryAccent
          style={{
            margin: `${svars.spaceMediumLarge} 0`,
            marginBottom: svars.spaceLarge,
          }}
        >
          <Trans id="back-to-normal" />
        </ButtonSecondaryAccent>
      </RouterLink>
      <div>
        {name ? (
          <>
            (<Trans>detected browser: {name}</Trans> <Icon name={icon} />)
          </>
        ) : null}
      </div>
    </div>
  );
}

function ErrorBoundary() {
  const error = useRouteError();
  Sentry.captureException(error);
  return (
    <>
      <LogoHomeLink />
      <EmptyDataPage
        maxHeight="300px"
        layout="row"
        i18nHeaderText={t({ id: "general-error-boundary.title" })}
        illustrationUrl={emptyDashboardUrl}
        actionComponent={<ErrorActions />}
      />
    </>
  );
}

export default ErrorBoundary;
